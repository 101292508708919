import React from "react";
import Layout from "../../components/Layout"
import Signup from "../../components/EmailListSignupDemo"
import SubSenseCollectionScript from "../../components/SubSenseCollectionScript";

const NewsSignup = () => (
  <Layout pageTitle="Sign up for our mailing list | Subscribe Sense" pageDescription="Subscribe to our mailing list." footer_children={<SubSenseCollectionScript/>}>
  </Layout>
);

export default NewsSignup;